import { render, staticRenderFns } from "./Solidaire.vue?vue&type=template&id=308fcc20&scoped=true&"
import script from "./Solidaire.vue?vue&type=script&lang=js&"
export * from "./Solidaire.vue?vue&type=script&lang=js&"
import style0 from "./Solidaire.css?vue&type=style&index=0&id=308fcc20&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308fcc20",
  null
  
)

export default component.exports