<template>
  <div id="app">
    <head-top></head-top>
    <router-view></router-view>
    <foot-bot></foot-bot> 
    
  </div>
</template>

<script>
import Header from './components/header/Header.vue'
import Footer from './components/footer/Footer.vue'


export default {
  name: 'App',
  components: {
    'head-top': Header,    
    'foot-bot': Footer,    
  }
}
</script>

<style>

</style>
