<template>    
    <div class="sidebloc p-3">
        <div class="text-center">
            <h3 class="sidebar-title mb-3">RDV en ligne</h3>
            <p><em>Prenez rendez-vous en ligne sur mon agenda</em></p>
            <button class="btn-rdv wavyOpenButton shake">Prendre rendez-vous</button>
        </div>
        <div class="text-center mt-3 mb-3">
            <h3 class="sidebar-title mb-3">Actualité</h3>
            <a href="https://www.facebook.com/audreybcoiffure" target="_blank"><img src="../../assets/facebook.png" alt="icone facebook" class="mr-3 shake"><img></a>
            <a href="https://www.instagram.com/audreybbeautee/?hl=fr" target="_blank"><img src="../../assets/instagram.png" alt="icone instagram" class="ml-3 shake" ><img></a>
        </div>
        <div class="text-center">
            <h3>Horaires</h3>
            <p><em>Mardi, Jeudi, Vendredi</em><br>09h - 19h</p>
            <p><em>Mercredi</em><br> 09h - 12h</p>
            <p><em>Samedi</em><br> 09h30 - 16h</p>
        </div>
        <div class="text-center">
            <h3>Adresse</h3>
            <p>47 rue de la libération<br>73300 Saint jean de maurienne</p>
            <p><b>TEL: 04.79.64.20.57</b></p>
        </div>
    </div>
        
</template>

<script>

export default {
    name: 'Sidebar',
    components: {        
    }, 
    mounted(){
        (function(w, a, v, y) {
        w = a.createElement(y);w.async = 1;w.defer = 1;w.src = v;
        y = a.getElementsByTagName(y)[0];y.parentNode.insertBefore(w, y);
        })(window, document, 'https://booking.wavy.pro/audreyb/widget.js?buttonclass=wavyOpenButton', 'script');
    }
    
}
</script>

<style scoped src="./Sidebar.css">

</style>