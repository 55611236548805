<template>
    <section class="presta mb-3 p-3">    
        <article>
            <b-row class="justify-content-center">
                <b-col sm="10" lg="8" class="ml-3 mr-3 mt-3 p-2">
                    <h2 class="text-center mb-4">Une gamme de services pour tous:</h2>
                    <b-row>
                        <picture>                       
                            <img src="../../assets/hair-tools.jpg" alt="matériel de coiffure" class="presta__pics img-fluid mx-auto mb-3">
                        </picture>                        
                        <p><b>Coiffure:</b> lissage, mise en boucle, coloration avec ou sans ammoniaque, balayage, painting, tie and dye, contouring, coupe transformation.<br>
                        <em><span  class="presta__plus">Le petit plus:</span> Soin Blow-tox  + power mask pour une brillance au top avec effet miroir</em></p>
                        <p ><b>Maquillage:</b> coaching maquillage, maquillage de jour et soirée.</p>
                        
                    </b-row>
                    <p class="presta__bloc--text mt-4">Pour chacun il existe un soin adapté pour un look moderne et tendance.</p>
                    <div class="text-center">
                        <button class="btn-rdv wavyOpenButton shake">Prendre rendez-vous</button>
                    </div>
                    <section class="presta__bloc">
                    <h3 id="tarifs" class="presta__bloc--title">Les tarifs</h3>
                    <h4 class="table__title">Tarifs femmes</h4>
                    <p>(Compris dans le forfait : 2 shampoing traitant, soin adapté aux cheveux)</p>                   
                    <p class="hairlength"><em>Voir les différentes longueurs de cheveux en bas de page</em></p>                   
                    <table>
                        <thead>
                            <th class="presta__cell">Prestations</th>
                            <th class="tarif__cell">Prix</th>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Brushing<br><em class="hairlength">court / mi-long / long / X-long</em></th>
                                <th class="tarif__cell">26 à 35 €</th>
                            </tr>
                            <tr>
                                <th>Coupe + Brushing<br><em class="hairlength">court / mi-long / long / X-long</em></th>
                                <th class="tarif__cell">40 à 49 €</th>
                            </tr>
                            <tr>
                                <th>Couleur + Coupe + Brushing<br><em class="hairlength">court / mi-long / long / X-long</em></th>
                                <th class="tarif__cell">65 à 74 €</th>
                            </tr>
                            <tr>
                                <th>Mèches | Painting | Ombré hair<br><em class="hairlength">court / mi-long / long / X-long</em></th>
                                <th class="tarif__cell">106 à 151 €</th>
                            </tr>
                            <tr>
                                <th>Lissage<br><em class="hairlength">court / mi-long / long / X-long</em></th>
                                <th class="tarif__cell">150 à 250 €</th>
                            </tr>
                            <tr>
                                <th>Permanente<br><em class="hairlength">court / mi-long / long / X-long</em></th>
                                <th class="tarif__cell">75 à 91 €</th>
                            </tr>
                            <tr>
                                <th>Chignon ou tresse<br><em class="hairlength">court / mi-long / long / X-long</em></th>
                                <th class="tarif__cell">25 à 35 €</th>
                            </tr>
                            <tr>
                                <th>Prestation mariage<br><em class="hairlength">Possibilité de privatiser le salon</em></th>
                                <th class="tarif__cell">A partir de <br> 150 €</th>
                            </tr>
                        </tbody>
                    </table>
                    </section>
                    <section class="presta__bloc">
                    <h4 class="table__title">Soins profonds</h4>
                    <p>(Compris dans le forfait : 2 shampoing traitant, soin et brushing)</p>
                    <table class="table__bloc">
                        <thead>
                            <th>Prestations</th>
                            <th class="tarif__cell">Prix</th>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Blowtox<br><em class="hairlength">court / mi-long / long / X-long</em></th>
                                <th class="tarif__cell">50 à 80 €</th>
                            </tr>
                            <tr>
                                <th>Power mask<br><em class="hairlength">court / mi-long / long / X-long</em></th>
                                <th class="tarif__cell">41 à 60 €</th>
                            </tr>
                            <tr>
                                <th>Soin combiné Blowtox + Power mask<br><em class="hairlength">court / mi-long / long / X-long</em></th>
                                <th class="tarif__cell">70 à 110 €</th>
                            </tr>
                            <tr>
                                <th>Olaplex additif anti casse coloration</th>
                                <th class="tarif__cell">10 €</th>
                            </tr>                           
                        </tbody>
                    </table>
                    </section>
                    <section class="presta__bloc">
                    <h4 class="table__title">Tarifs hommes</h4>
                    <p>(Shampoing, gel, huile et After shave compris)</p>
                    <table class="table__bloc">
                        <thead>
                            <th>Prestations</th>
                            <th class="tarif__cell">Prix</th>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Coupe</th>
                                <th class="tarif__cell">20 €</th>
                            </tr>
                            <tr>
                                <th>Papy (couronne)</th>
                                <th class="tarif__cell">15 €</th>
                            </tr>
                            <tr>
                                <th>Coupe + barbe (tondeuse)</th>
                                <th class="tarif__cell">30 €</th>
                            </tr>
                            <tr>
                                <th>Coupe + moustache (tondeuse)</th>
                                <th class="tarif__cell">25 €</th>
                            </tr>
                            <tr>
                                <th>Permanente ou mèches (dessus de tête)</th>
                                <th class="tarif__cell">30 €</th>
                            </tr>
                        </tbody>
                    </table>
                    </section>
                    <section class="presta__bloc">
                    <h4 class="table__title">Tarifs juniors</h4>
                    <p>(Shampoing et soin traitant compris hors forfait baby)</p>
                    <table class="table__bloc">
                        <thead>
                            <th>Prestations</th>
                            <th class="tarif__cell">Prix</th>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Baby - de 2ans</th>
                                <th class="tarif__cell">10 €</th>
                            </tr>
                            <tr>
                                <th>Girly - de 10 ans</th>
                                <th class="tarif__cell">26 €</th>
                            </tr>
                            <tr>
                                <th>Boy - de 10 ans</th>
                                <th class="tarif__cell">15 €</th>
                            </tr>
                            <tr>
                                <th>Ado Girl - de 15 ans</th>
                                <th class="tarif__cell">32 €</th>
                            </tr>
                            <tr>
                                <th>Ado Boy - de 15 ans</th>
                                <th class="tarif__cell">18 €</th>
                            </tr>
                        </tbody>
                    </table>
                    </section>
                    <h2  id="hairlength" class="text-center mb-4 mt-4">Les différentes longueurs de cheveux</h2>
                    <b-row class="justify-content-center">
                        <picture>                       
                            <img src="../../assets/Longueur-cheveux.jpeg" alt="Longueur des cheveux" class="presta__pics img-fluid mx-auto mb-3">
                        </picture>                                               
                    </b-row>
                </b-col>
            </b-row>
        </article>        
    </section>    
</template>

<script>


export default {
    name: 'Presta',

    components: {
            
    },
    mounted(){
        (function(w, a, v, y) {
        w = a.createElement(y);w.async = 1;w.defer = 1;w.src = v;
        y = a.getElementsByTagName(y)[0];y.parentNode.insertBefore(w, y);
        })(window, document, 'https://booking.wavy.pro/audreyb/widget.js?buttonclass=wavyOpenButton', 'script');
    }
    
}
</script>
<style scoped src="./Presta.css">

</style>